import React, { useState } from "react";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import { uploadFile } from "apis/master";
import { createNotifications } from "apis/notification";

export default function CreateNotifications() {
  const color = "light";
  const [imagePreview, setImagePreview] = useState(null);
  const [payload, setPayload] = useState({
    body: "",
    image: "",
    isImportant: true,
    isActive: true,
    title: "",
  });

  const handleTitleChange = (e) => {
    const { value } = e.target;
    setPayload((prev) => ({
      ...prev,
      title: value,
    }));
  };
  const handleBodyChange = (e) => {
    const { value } = e.target;
    setPayload((prev) => ({
      ...prev,
      body: value,
    }));
  };

  const handleCreateNotifications = () => {
    createNotifications(payload)
      .then((res) => {
        toast("Notification created successfully", {
          type: "success",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        setPayload({
          title: "",
          image: "",
          body: "",
        });
        setImagePreview(null);
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        // if (err.response.status === 401) {
        //   localStorage.clear();
        //   navigate("/");
        // }
      });
  };

  // const handleImageChange = (event) => {
  //   const file = event.target.files[0];
  //   setPayload({
  //     ...payload,
  //     imageUrl: file,
  //   });
  //   setImagePreview(URL.createObjectURL(file));
  // };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setImagePreview(URL.createObjectURL(file));

    let body = new FormData();
    body.append("document", file);

    try {
      const { data } = await uploadFile(body, "NOTIFICATION");
      setPayload((prev) => ({ ...prev, image: data.data.url }));
    } catch (err) {
      toast(err.response?.status + " " + err?.response?.data?.message, {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    }
  };

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        {/* ADD SERIES DIALOG */}
        <DialogTitle>Cretate Notifications</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Title"
                variant="outlined"
                fullWidth
                value={payload.title}
                onChange={handleTitleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Description"
                variant="outlined"
                fullWidth
                value={payload.body}
                onChange={handleBodyChange}
              />
            </Grid>
            <Grid item xs={12}>
              <input
                accept="image/*"
                id="payment-screenshot"
                type="file"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
              <label htmlFor="payment-screenshot">
                <Button variant="contained" component="span">
                  Upload Image
                </Button>
              </label>
              {imagePreview && (
                <img
                  src={imagePreview}
                  alt="Image Preview"
                  style={{ marginTop: 10, maxHeight: 100 }}
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateNotifications}
          >
            Create
          </Button>
        </DialogActions>
      </div>
    </>
  );
}
