import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import TableHeader from "components/Table/TableHeader";
import TableElement from "components/Table/TableElement";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { getDevices, deleteDevice } from "apis/customerRegistration"; // Assuming deleteDevice is the API function
import { useDispatch, useSelector } from "react-redux";
import { setLoadingPanel } from "../../redux/features/loading";
import CircularIndeterminateModal from "../../utils/Loading";

export default function Devices() {
  const color = "light";

  const [devices, setDevices] = useState([]);
  const isLoading = useSelector((state) => state.loadingReducer.isLoading);
  const dispatch = useDispatch();
  const [pageToken, setPageToken] = useState(null);
  const [navigate, setNavigate] = useState({ next: null, prev: null });
  const [page, setPage] = useState(0);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [enterpriseId, setEnterpriseId] = useState("");
  const [deviceId, setDeviceId] = useState("");

  const handleGetDevices = async () => {
    try {
      dispatch(setLoadingPanel(true));
      const { data } = await getDevices(pageToken);
      setDevices(data.devices);
      setNavigate({ next: data.nextPageToken });
      setPage((prev) => (!pageToken ? 0 : prev + 1));
      dispatch(setLoadingPanel(false));
    } catch (err) {
      toast(err.response?.status + " " + err?.response?.data?.message, {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
      dispatch(setLoadingPanel(false));
    }
  };

  const handleDeleteDevice = async () => {
    try {
      dispatch(setLoadingPanel(true));
      await deleteDevice({ enterprise: enterpriseId, deviceId });
      toast("Device deleted successfully", {
        type: "success",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
      setDeleteDialogOpen(false);
      handleGetDevices(); // Refresh the device list after deletion
    } catch (err) {
      toast(err.response?.status + " " + err?.response?.data?.message, {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    } finally {
      dispatch(setLoadingPanel(false));
    }
  };

  useEffect(() => {
    handleGetDevices();
  }, [pageToken]);

  return (
    <>
      <CircularIndeterminateModal open={isLoading} />
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }>
        <div className="flex justify-evenly my-4">
          <Button
            type="submit"
            disabled={!navigate.next}
            variant="contained"
            onClick={() => {
              if (navigate.next) {
                setPageToken(navigate.next);
              }
            }}>
            Next
          </Button>
          <Button
            type="button"
            variant="contained"
            color="secondary"
            onClick={() => setDeleteDialogOpen(true)}>
            Delete
          </Button>
        </div>
        <div className="block w-full overflow-x-auto p-8">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr className="divide-x divide-gray-200">
                <TableHeader heading="S No." />
                <TableHeader heading="Name" />
                <TableHeader heading="Serial Number" />
                <TableHeader heading="IMEI" />
                <TableHeader heading="Model" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {devices.map((device, i) => (
                <tr
                  key={i}
                  className="divide-x divide-gray-200 hover:bg-slate-100">
                  <TableElement value={page * 100 + i + 1} />
                  <TableElement
                    value={
                      <span className="font-semibold">{device?.name}</span>
                    }
                    type="element"
                  />
                  <TableElement
                    value={
                      <span className="font-semibold">
                        {device?.hardwareInfo?.serialNumber}
                      </span>
                    }
                    type="element"
                  />
                  <TableElement
                    value={
                      <span className="font-semibold">
                        {device?.networkInfo?.imei}
                      </span>
                    }
                    type="element"
                  />
                  <TableElement
                    value={
                      <div className="flex flex-col">
                        <span className="font-semibold">
                          {device?.hardwareInfo?.brand}
                        </span>
                        <span className="font-semibold">
                          {device?.hardwareInfo?.model}
                        </span>
                      </div>
                    }
                    type="element"
                  />
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Delete Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Delete Device</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Enterprise ID"
            type="text"
            fullWidth
            variant="outlined"
            value={enterpriseId}
            onChange={(e) => setEnterpriseId(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Device ID"
            type="text"
            fullWidth
            variant="outlined"
            value={deviceId}
            onChange={(e) => setDeviceId(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteDevice} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
